import _export from "../internals/export";
import _freezing from "../internals/freezing";
import _fails from "../internals/fails";
import _isObject from "../internals/is-object";
import _internalMetadata from "../internals/internal-metadata";
var $ = _export;
var FREEZING = _freezing;
var fails = _fails;
var isObject = _isObject;
var onFreeze = _internalMetadata.onFreeze; // eslint-disable-next-line es-x/no-object-freeze -- safe

var $freeze = Object.freeze;
var FAILS_ON_PRIMITIVES = fails(function () {
  $freeze(1);
}); // `Object.freeze` method
// https://tc39.es/ecma262/#sec-object.freeze

$({
  target: "Object",
  stat: true,
  forced: FAILS_ON_PRIMITIVES,
  sham: !FREEZING
}, {
  freeze: function freeze(it) {
    return $freeze && isObject(it) ? $freeze(onFreeze(it)) : it;
  }
});
export default {};